@import "settings";

.region-sub-content {
  margin-bottom: $global-margin;

  .card {
    @include card-container(
      $footer-background-color,
      $footer-font-color,
      $global-margin,
      $footer-card-border,
      $global-radius,
      $footer-card-shadow
    );

    .card-divider {
      background-color: $footer-card-divider-background-color;
      color: $footer-card-divider-font-color;
    }

    a {
      color: $footer-font-color;
    }
  }

  justify-content: space-between;

  & > * {
    flex: 1;
  }

  & > :not(:last-child) {
    margin-right: $global-padding;
  }

  $tabs-background: #{lighten($footer-background-color, 20%)};
  @if $footer-tabs-style == open {
    $tabs-background: $footer-background-color;
  }

  *:not(hr) {
    border: none;
  }
  .tabs {
    display: flex;
    border: none;
    background-color: $tabs-background;

    .tabs-title {
      flex: 1;
      background-color: $footer-background-color;

      &.is-active a {
        background-color: $footer-card-divider-background-color;
      }

      a {
        text-align: center;
        color: $footer-card-divider-font-color;

        @include breakpoint(small down) {
          padding-left: #{$global-padding / 2};
          padding-right: #{$global-padding / 2};
        }
      }

      a:hover {
        background-color: $tabs-background;
      }
    }
  }

  .tabs-content {
    background-color: $tabs-background;
    border: none;
    * {
      color: $footer-font-color;
    }
  }

  .card {
    background-color: transparent;
  }
}
