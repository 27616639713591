@import "settings";

.views-view-grid.cols-2,
.views-view-grid.cols-3 {

    @include breakpoint(medium) {
        @include breakpoint(xlarge down) {
            h1 {
                font-size: 36px;
            }
    
            h2 {
                font-size: 34px;
            }
    
            h3 {
                font-size: 24px;
            }
    
            h4 {
                font-size: 21px;
            }
    
            h5 {
                font-size: 18px;
            }
    
            h6 {
                font-size: 16px;
            }

        }
    }
}