.node--view-mode-hiag-card {
    .card-divider {

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-bottom: 0px;
        }
    }
}