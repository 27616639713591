@import "settings";

.node--view-mode-full {
  & > .node__content > * > .field + .field,
  & > .node__content > .field + .field {
    margin-top: $global-margin;
  }

  .grid-margin-x > .cell.page-main-content:not(:last-child) {
    @include breakpoint(#{$view-mode-full-content-stacked-breakpoint} up) {
      margin-right: $view-mode-full-sub-content-size-adjust;
    }
  }
  .page-main-content {
    @include content-use-flex-labels-inline();
  }

  .page-sub-content {
    @include breakpoint(#{$view-mode-full-content-stacked-breakpoint} down) {
      margin-top: $global-margin;
    }

    @include breakpoint(#{$view-mode-full-content-stacked-breakpoint} up) {
      position: relative;
      height: fit-content;

      @if ($view-mode-full-sub-content-card-style) {
        @include auto-regional-colors($card-background);
        padding: #{$view-mode-full-sub-content-padding / 2};

        .button {
          $button-color: color-pick-contrast(
            $card-background,
            $button-color $button-color-alt
          );
          @include button-hollow-style(
            $button-color,
            $button-hollow-hover-lightness,
            $button-hollow-border-width
          );

          * {
            color: $button-color;
          }
        }
        // --> add shadow
        &:before {
          content: "";
          position: absolute;
          height: calc(100% + #{$view-mode-full-sub-content-padding});
          width: calc(100% + #{$view-mode-full-sub-content-size-adjust});
          top: #{-1 * $view-mode-full-sub-content-padding / 2};
          right: #{-1 * $view-mode-full-sub-content-padding / 2};
          background: $card-background;
          border: $card-border;
          box-shadow: $card-shadow;
          border-radius: $card-border-radius;
        }
        .field__label {
          color: color-pick-contrast(
            $card-background,
            change-color($field-label-color, $alpha: $field-label-alpha)
              change-color($field-label-color-dark, $alpha: $field-label-alpha)
          );
        }
        .field__item {
          color: $card-font-color;
        }

        a {
          color: color-pick-contrast(
            $card-background,
            $anchor-color $anchor-color-dark
          );
        }
      }

      & > * {
        --field-width-adjust: calc(
          #{$view-mode-full-sub-content-size-adjust} - #{$view-mode-full-sub-content-padding}
        );
        position: relative;
        z-index: 1;
        width: calc(100% + var(--field-width-adjust));
        transform: translateX(calc(-1 * var(--field-width-adjust)));
      }
    }
  }

  .page-leftover-content {
    margin-top: $global-margin;
  }
}
