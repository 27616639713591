@import "settings";

// Configure side-by-side
.container--hiag-side-by-side {
  display: flex;
  flex-wrap: wrap;
  gap: $global-margin;
  & > :not(.block--hiag-side-by-side) {
    width: 100%;
  }
  & > .block--hiag-side-by-side {
    width: calc(50% - #{$global-margin / 2});
    max-width: calc(50% - #{$global-margin / 2});

    @include breakpoint(medium down) {
      width: 100%;
    }
  }
}

// Configure zebra
.hiag-zebra {
  .view-content > .views-row:nth-child(odd) {
    background-color: $light-gray;
  }
  .view-content > .views-row:nth-child(even):last-child {
    border-bottom: $global-std-border;
  }
}

// Configure worm
@mixin hiag-worm($worm-border) {
  .hiag-worm {
    // unformatted
    .view-content > .views-row {
      border-top: $worm-border;
    }
    .view-content > .views-row:last-child {
      border-bottom: $worm-border;
    }

    // table
    table {
      tbody:first-child,
      thead:first-child,
      tfoot:first-child {
        border-top: $table-border;
      }

      tr {
        border-bottom: $table-border;
      }

      thead,
      tbody,
      tfoot {
        border-left-color: transparent;
        border-right-color: transparent;
      }

      tbody {
        border-color: transparent;
      }

      tbody tr:nth-child(even) {
        background-color: transparent;
        border-bottom: $worm-border;
      }
    }
  }
}

body {
  @include hiag-worm($global-std-border);
}

// Configure grid
.hiag-grid {
  @each $bp, $gap in $grid-margin-gutters {
    @include breakpoint($bp up) {
      --current-gap: #{$gap / 2};
    }
  }
  .view-content {
    display: flex;
    flex-wrap: wrap;
    gap: var(--current-gap);
  }

  .views-row {
    width: 100%;
  }

  @for $cols from 2 through 12 {
    &.hiag-cols-#{$cols} {
      --per-row-margin: calc(var(--current-gap) * #{($cols - 1) / $cols});
      --per-row-width: #{100 / $cols}#{"%"};
      .views-row {
        width: calc(var(--per-row-width) - var(--per-row-margin));
      }
    }

    @each $name, $value in $breakpoints {
      @include breakpoint($name up) {
        &.hiag-cols-#{$cols}-#{$name} {
          --per-row-margin: calc(var(--current-gap) * #{($cols - 1) / $cols});
          --per-row-width: #{100 / $cols}#{"%"};
          .views-row {
            width: calc(var(--per-row-width) - var(--per-row-margin));
          }
        }
      }
    }
  }
}

// Configure Accordion
.hiag-accordion,
.view[class^="hiag-accordion--"] {
  .accordion {
    background: none;

    .accordion-item {
      background: $accordion-background;
    }
  }
  .views-sorting-title {
    margin-top: $accordion-group-title-margin;
  }
  .last-accordion-item {
    border-bottom: $accordion-content-border;
  }
  .accordion-content > .views-field:not(:last-child) {
    margin-bottom: $global-margin;
  }

  &[class*="--no-gutters"] {
    .accordion-content {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

// Configure Tabs
.view.hiag-tabs {
  @include tabs-to-buttons;
}

// Configure Equalizer
.views-row[data-equalizer-watch^="views-equalizer"] > * {
  height: 100%;
}

// Configure HIAG Mini
.hiag-mini {
  .node--view-mode-teaser {
    .field--name-title {
      font-size: $global-font-size;
    }

    .teaser-info-section {
      padding-top: $global-padding / 2;
      .field,
      .field__label {
        font-size: 80%;
        line-height: 1;
      }
    }
  }

  .node--view-mode-hiag-card {
    &.node--type-person,
    &.node--type-school-person {
      .hiag-person-header {
        .hiag-person-title-section {
          margin-top: 0;
          margin-bottom: 0;
        }
        .field--name-field-portrait img {
          height: 120px;
          width: auto;
        }
      }
    }
  }

  &.hiag-shortcuts-view {
    .view-content {
      gap: #{$shortcut-gap * $shortcut-mini-multiplyer};
    }
    .hiag-shortcut-button.button {
      padding: #{$shortcut-padding * $shortcut-mini-multiplyer};

      .material-symbols-outlined {
        font-size: #{$shortcut-size * $shortcut-mini-multiplyer};
      }

      .field--name-field-custom-icon {
        height: #{$shortcut-size * $shortcut-mini-multiplyer};
        width: #{$shortcut-size * $shortcut-mini-multiplyer};
      }
      .shortcut-title {
        max-width: #{$shortcut-size * $shortcut-mini-multiplyer};
        display: flex;
        justify-content: center;
        font-size: $shortcut-mini-font-size;
      }
    }
  }
}

// Configur dropdown
.hiag-dropdown,
.hiag-reveal {
  .button {
    margin-bottom: 0;

    &[disabled] {
      @if ($table-hide-disabled-buttons) {
        visibility: hidden !important;
        opacity: 0 !important;
        cursor: default !important;
      }
    }
    span.hiag-table-stacked-header {
      font-size: 100%;
      color: $button-color;
      margin-right: 4px;
    }
  }

  .hiag-table-stacked-content {
    display: flex;
    justify-content: right;

    @include breakpoint($table-stack-breakpoint #{down}) {
      justify-content: left;

      .button {
        width: 100%;
        max-width: 420px;
      }
    }
  }

  &.views-field-field-downloads {
    .material-symbols-outlined {
      @include set-icon("download");
    }
  }
  &.views-field-field-links {
    .material-symbols-outlined {
      @include set-icon("links");
    }
  }
  &.views-field-body {
    .material-symbols-outlined {
      @include set-icon("info");
    }
  }
}

.hiag-reveal-panel.reveal {
  box-shadow: $reveal-shadow;
  .hiag-reveal-content {
    margin: $global-margin;
    max-height: $reveal-max-height;
  }
}

@each $align in (top, bottom, middle, baseline) {
  .table--vertical-align--#{$align} > .view-content > table > tbody > tr > td {
    vertical-align: $align;
  }
}

.grouping-title-inline {
  .view-grouping {
    display: flex;
    gap: $global-margin;
    margin-bottom: 2 * $global-margin;

    .view-grouping-header {
      margin: $global-margin;
    }

    .view-grouping-content {
      flex: 1;
    }

    .teaser-label--date--month-name {
      display: none;
    }
  }
}
