@import "settings";
.decode-link {
  color: $anchor-color;
  cursor: pointer;
  background-color: transparent;
  width: max-content;
  transition: background-color 50ms;
  border-radius: $global-radius;
  user-select: none;

  &.decode-link--triggered {
    background-color: change-color(
      map-get($foundation-palette, "success"),
      $alpha: 0.5
    );
  }
}

.region-content {
  .page-sub-content,
  .card {
    .decode-link {
      @include breakpoint(#{$view-mode-full-content-stacked-breakpoint} up) {
        color: color-pick-contrast(
          $card-background,
          $anchor-color $anchor-color-dark
        );
      }
    }
  }
}
