@import "settings";

.node--type-events {
  &.node--view-mode-full {
    .field + .field {
      margin-top: $global-margin;
    }

    .field__item.material-symbols-outlined {
      @each $breakpointName, $breakpointValues in $header-styles {
        @include breakpoint($breakpointName) {
          $currentIconSize: map-get(
            map-get($breakpointValues, "h1"),
            "font-size"
          );
          font-size: #{$currentIconSize}px;
        }
      }
    }
    .page-main-content .field__item.material-symbols-outlined {
      float: right;
      @each $breakpointName, $breakpointValues in $header-styles {
        @include breakpoint($breakpointName) {
          $currentIconSize: map-get(
            map-get($breakpointValues, "h1"),
            "font-size"
          );
          transform-origin: center bottom;
          transform: translateY(
              calc(#{$currentIconSize * -1}px - #{$global-margin})
            )
            scale(120%);
        }
      }
    }
  }

  &.node--view-mode-hiag-blank {
    .node__content > .material-symbols-outlined {
      float: right;
      font-size: $global-font-size * 2;
    }

    .hiag-blank-date-time + .field {
      margin-top: $global-margin;
    }
  }
}
