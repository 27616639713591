@import "settings";

.region-sub-features {
  margin-bottom: $global-margin;
  justify-content: space-between;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  padding-bottom: $global-margin;
  background-color: $footer-card-divider-background-color;
  flex-wrap: wrap;

  & > * {
    flex: 1;
    min-width: 250px;
  }

  & > :not(:last-child) {
    margin-right: $global-padding;
  }

  .card {
    @include card-container(
      $footer-card-divider-background-color,
      $footer-font-color,
      $global-margin,
      $footer-card-border,
      $global-radius,
      $footer-card-shadow
    );

    .card-divider {
      background-color: $footer-card-divider-background-color;
      color: $footer-card-divider-font-color;
    }

    a {
      color: $footer-font-color;
    }
  }
}
