@import "settings";

.region-slider {
    .card {
        @include card-container($slider-background-color,
            $slider-font-color,
            $global-margin,
            $slider-card-border,
            $global-radius,
            $slider-card-shadow);

        .card-divider {
            background-color: $slider-card-divider-background-color;
            color: $slider-card-divider-font-color;
        }
    }
}