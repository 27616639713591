@import "settings";

.field--name-field-gallery-image {
  .hiag-gallery-image-button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 150px;
      height: 150px;
      object-fit: cover;
      transition: filter 0.4s;
    }
    &:before {
      @include mdi-icon("\f830"); // expand_content
      position: absolute;
      font-size: 120px;
      color: transparent;
      transform: scale(1);
      transition: transform 0.4s, color 0.4s;
    }
    &:hover {
      &:before {
        color: $primary-color;
        transform: scale(1.5);
      }
    }
  }
}

.hiag-orbit-reveal {
  @include breakpoint(medium up) {
    .orbit-figure img {
      width: 100%;
    }

    .orbit-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      * {
        max-height: calc(100vh - 56px);
      }
    }
    img {
      object-fit: contain;
      min-height: 400px;
    }
  }

  @include breakpoint(medium down) {
    display: flex !important;
    flex-direction: column;
    justify-content: center;

    .orbit-container {
      display: flex;
      justify-content: center;
      max-height: 100vh;

      * {
        max-height: 100vh;
      }

      .orbit-slide {
        width: 100%;
      }
    }

    img {
      object-fit: contain;
      width: 100%;
    }
  }

  .orbit-caption-top {
    white-space: nowrap;
    color: $white;
    background-color: $orbit-caption-background;
    padding: #{$orbit-caption-padding * 0.5};
  }

  .close-button {
    display: flex;
    color: $white;
    background-color: $orbit-caption-background;
  }
}

// Fix sticky not working with image-gallery
.sticky.is-stuck--under-reveal {
  position: fixed;
  z-index: 5;
  width: 100%;
}
