@import "settings";

.off-canvas.position-left {
  .region-left-off-canvas {
    .card {
      background-color: transparent;
      box-shadow: none;
      border-color: transparent;
    }
    &:first-child {
      margin-top: calc(var(--height-hiag-main-menu) + #{$global-margin});
    }
  }
}

.hiag-sidebars-off-canvas-toggle-container {
  z-index: #{$offcanvas-overlap-zindex - 1};
  box-shadow: none !important;
  & > .button {
    box-shadow: $global-shadow-small;
  }
}
