@import "settings";

.region.region-bottom-top-bar {
}

.hiag-title-bar-bottom-wrapper {
  width: 100vw;
  display: flex;
  background-color: $topbar-bottom-background-color;
  align-items: center;
  justify-content: center;

  .button {
    margin: 0;
  }
}
