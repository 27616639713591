@import "settings";

.view {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: $global-margin;

  & > * {
    flex: 1;
  }

  .view-footer {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .feed-icons {
    display: none;
  }
}

div.views-field + div.views-field {
  margin-top: $global-margin;
}

.hiag-view-field-overrides--sub-content {
  @include breakpoint(medium up) {
    @include card-container();
    float: right;
    margin-left: $global-margin;
    width: 25%;

    & > * {
      @include card-section;
      margin-top: 0 !important;
    }
  }
}

[class*="hiag-view-field-overrides--field"]
  + [class*="hiag-view-field-overrides--field"] {
  margin-top: $global-margin;
  padding-top: 0;
}

[class*="hiag-view-field-overrides--field"] > img {
  width: 100%;
}

.hiag-view-field-overrides--field-inline {
  display: flex;
  gap: $global-margin;

  .hiag-view-field-overrides--field-label:after {
    content: ":";
  }
}
.hiag-view-field-overrides--field-label {
  @include hiag-label($field-label-color, $field-label-alpha);
}
