@import "settings";

.region-backdrop {
  .card {
    @include card-container(transparent, $black, 0, none, 0, none);

    .card-divider {
      background-color: transparent;
      color: $black;
    }

    .card-section {
      padding: 0;
    }
  }
}

#block-banner-fill {
  visibility: hidden;
  display: none;
  position: absolute;

  &:first-child:last-child {
    visibility: visible;
    display: block;
    position: relative;
  }
}

@each $block in $orbit-backdrop-fixed-blocks {
  .region-backdrop #{$block} {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    overflow: visible;
  }

  body[data-user-role*="administrator"] .region-backdrop #{$block} {
    top: 53px;
  }
}

.region-backdrop {
  --current-orbit-image-height: 420px;
  &.fixed-orbit-container {
    height: var(--current-orbit-image-height);
    position: relative;
  }
}
