@import "settings";

.hiag-main-menu-dropdown-container {
  @if $dropdownmenu-underline {
    .hiag-menu-underline {
      position: fixed;
      transition: width 0.4s, left 0.4s, opacity 0.4s;
    }
  } @else {
    .hiag-menu-underline {
      display: none;
    }
  }

  @each $br, $gap in $dropdownmenu-gap {
    @if $br == default {
      gap: $gap;
    } @else {
      @include breakpoint(#{$br} down) {
        gap: $gap;
      }
    }
  }

  & > .is-dropdown-submenu-parent > .is-dropdown-submenu {
    border-bottom-left-radius: $dropdownmenu-submenu-radius;
    border-bottom-right-radius: $dropdownmenu-submenu-radius;

    & > .is-submenu-item:last-child {
      border-bottom-left-radius: $dropdownmenu-submenu-radius;
      border-bottom-right-radius: $dropdownmenu-submenu-radius;
    }

    .is-dropdown-submenu {
      border-radius: $dropdownmenu-submenu-radius;

      .is-submenu-item:first-child {
        border-top-left-radius: $dropdownmenu-submenu-radius;
        border-top-right-radius: $dropdownmenu-submenu-radius;
      }

      .is-submenu-item:first-child {
        border-bottom-left-radius: $dropdownmenu-submenu-radius;
        border-bottom-right-radius: $dropdownmenu-submenu-radius;
      }
    }
  }

  .hiag-menu-underlined-link > a {
    font-size: $dropdownmenu-font-size;
  }

  @if $dropdownmenu-arrows-hide-first-level {
    & > .is-dropdown-submenu-parent {
      a {
        padding: $dropdownmenu-submenu-padding;
      }

      &::after {
        content: none;
      }
    }
  }

  .opens-right {
    text-align: left;

    & > ul > li.opens-left {
      text-align: left;
    }
  }

  .opens-left {
    text-align: right;
  }

  & > .is-dropdown-submenu-parent > ul {
    .is-submenu-item:hover,
    .is-submenu-item.is-active {
      background-color: $dropdownmenu-submenu-item-hover-color;

      & > a {
        color: color-pick-contrast(
          $dropdownmenu-submenu-item-hover-color,
          $black $white
        );
        &::after {
          border-color: transparent transparent transparent
            color-pick-contrast(
              $dropdownmenu-submenu-item-hover-color,
              $black $white
            );
        }
      }
    }
  }

  .is-dropdown-submenu
    .is-dropdown-submenu-parent.opens-left.is-active
    > a::after {
    border-color: transparent
      color-pick-contrast($dropdownmenu-submenu-item-hover-color, $black $white)
      transparent transparent;
  }

  &.menu.horizontal {
    flex-wrap: nowrap;

    * {
      white-space: nowrap;
      word-break: keep-all;
    }
  }
}

.region-bottom-top-bar {
  .hiag-main-menu-dropdown-container {
    @if $dropdownmenu-underline {
      .hiag-menu-underline {
        background-color: color-pick-contrast(
          $topbar-bottom-background-color,
          $anchor-color $white
        );
        height: $dropdownmenu-underline-topbar-bottom-height;
        transform: translateY(-#{$dropdownmenu-underline-topbar-bottom-height});
      }
    } @else {
      .hiag-menu-underline {
        display: none;
      }
    }

    & > li > a,
    & > li.is-active > a {
      color: color-pick-contrast(
        $topbar-bottom-background-color,
        $anchor-color $white
      );

      &:hover {
        color: color-pick-contrast(
          $topbar-bottom-background-color,
          $anchor-color $white
        );
      }
    }
  }
}

.region-right-top-bar,
.region-left-top-bar {
  .hiag-main-menu-dropdown-container {
    @if $dropdownmenu-underline {
      .hiag-menu-underline {
        background-color: $primary-color;
        height: $titlebar-padding;
      }
    } @else {
      .hiag-menu-underline {
        display: none;
      }
    }

    & > .is-dropdown-submenu-parent > ul {
      transform: translateY($titlebar-padding);

      .is-submenu-item:hover {
        background-color: $dropdownmenu-submenu-item-hover-color;

        & > a {
          color: color-pick-contrast(
            $dropdownmenu-submenu-item-hover-color,
            $black $white
          );
        }
      }
    }
  }
}

.region-right-top-bar {
  .hiag-main-menu-dropdown-container {
    justify-content: right;
  }
}

// Required for Safari corresponding to handleSafariStickyMenuBug()-Function
header#hiag-main-menu.is-stuck {
  .hiag-title-bar-bottom-controller {
    transform: translateY(0px) !important;
  }
}
