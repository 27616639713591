@import "settings";

.node--view-mode-hiag-blank {
  & > .node__content > .field + .field,
  .hiag-blank-date-time > .field + .field {
    margin-top: $global-margin;
  }

  &:not(.node--type-header-content) > .node__content {
    overflow: auto;

    &::after {
      clear: both;
    }
  }

  @include content-use-flex-labels-inline();

  .field--name-field-image {
    float: right;
    max-width: 400px;
    min-width: 200px;
    margin-left: $global-margin;
    margin-bottom: $global-margin;

    @include breakpoint($field-label-inline-breakpoint down) {
      float: unset;
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      margin-left: 0px;
    }
  }

  &:not(.node--type-config-responsive-blocks) .field--name-body {
    position: relative;
    overflow-y: hidden;
    transition: height map-get($transition-speed, "base");

    .button.field--name-body--expand-more-button {
      position: absolute;
      right: 0;
      bottom: 0;
      transition: transform map-get($transition-speed, "base");
    }

    &:before {
      position: absolute;
      bottom: 0;
      right: 0;
      height: 50px;
      width: 100%;
      transition: opacity map-get($transition-speed, "base");
    }
    &.closed {
      height: 100px;
      &:before {
        content: "";

        opacity: 1;
        background: $main-content-background;
        background: -moz-linear-gradient(
          0deg,
          $main-content-background 0%,
          change-color($main-content-background, $alpha: 0) 100%
        );
        background: -webkit-linear-gradient(
          0deg,
          $main-content-background 0%,
          change-color($main-content-background, $alpha: 0) 100%
        );
        background: linear-gradient(
          0deg,
          $main-content-background 0%,
          change-color($main-content-background, $alpha: 0) 100%
        );
      }
    }

    &.expanded {
      height: calc(var(--body-inner-height) + 50px);
      &:before {
        content: "";
        opacity: 0;
      }
    }
  }
}
