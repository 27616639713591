@import "settings";

.node--type-config-responsive-blocks {
  .responsive-blocks-row,
  .responsive-blocks-column {
    display: flex;
    gap: $global-margin;
  }

  .responsive-blocks-column {
    flex-direction: column;

    .responsive-blocks-content__item {
      width: 100%;
    }
  }

  .responsive-blocks-content__item.card .wrapper-container-view {
    @include card-section;
  }

  .responsive-blocks-row {
    justify-content: space-between;

    .responsive-blocks-content__item {
      width: #{$global-width / 3};
      max-width: 540px;
      flex: 1;
    }
  }

  .responsive-blocks-orbit {
    .orbit-slide-content-wrapper {
      padding-left: #{$global-margin * 2};
      padding-right: #{$global-margin * 2};
      margin: auto;
      width: 100%;
    }

    .orbit-controls {
      button {
        color: $black;
      }
    }
  }
}
