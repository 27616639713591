
.region-right-top-bar {
    .hiag-menu-button-container {
        &.button-group {
            justify-content: right;
        }
    }
}

.region-left-top-bar {
    .hiag-menu-button-container {
        .button-group {
            justify-content: left;
        }
    }
}
