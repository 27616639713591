@import "settings";

.responsive-embed,
.flex-video {
  &[class*="align-"] {
    $ratio: map-get($responsive-embed-ratios, "default");
    width: 50%;
    padding-bottom: calc(#{ratio-to-percentage($ratio)} / 2);

    $ratios: map-remove($responsive-embed-ratios, default);
    @each $name, $ratio in $ratios {
      &.#{$name} {
        padding-bottom: calc(#{ratio-to-percentage($ratio)} / 2);
      }
    }
  }
}
