@import "settings";

.hiag-main-menu-tree-container {
  .hiag-main-menu-tree-is-active-trail > a {
    background-color: $menu-item-background-active-trail;
  }

  a:hover {
    background-color: $menu-item-background-hover;
    color: color-pick-contrast($menu-item-background-hover, $black $white);
  }
}

.hiag-sidebars-off-canvas-toggle-container {
  position: fixed;
  margin: $global-margin;
  bottom: 0;
  left: 0;
  box-shadow: $global-shadow-small;

  .button {
    margin: unset;
  }
}
