@import "settings";

.node--type-config-special-navigation {
  margin: $global-margin;
  background-color: $title-bar-card-background-color;

  a {
    white-space: nowrap;
  }

  & > .node__content {
    display: flex;
    justify-content: space-between;

    @include breakpoint(medium down) {
      flex-wrap: wrap;
      gap: $global-margin;

      .field--name-field-special-navigation-header {
        flex: 0 0 0%;
      }

      .field--name-field-special-navigation-content {
        flex: 1 1 0%;
      }
    }
  }

  &.config-special-navigation--align-bottom > .node__content {
    align-items: flex-end;
  }

  &.config-special-navigation--align-top > .node__content {
    align-items: flex-start;
  }

  &.config-special-navigation--align-center > .node__content {
    align-items: center;
  }

  nav.field--name-field-special-navigation-content {
    display: flex;
    justify-content: right;
    flex-wrap: wrap;

    @each $bp, $hide-array in $special-navigation-hide-array {
      @if $bp == default {
        @each $hide-index in $hide-array {
          & > *:nth-child(#{$hide-index}) {
            display: none !important;
          }
        }
      } @else {
        @include breakpoint(#{$bp} down) {
          @each $hide-index in $hide-array {
            & > *:nth-child(#{$hide-index}) {
              display: none !important;
            }
          }
        }
      }
    }

    @each $bp, $gap-value in $special-navigation-item-gap {
      @if $bp == default {
        gap: $gap-value;
      } @else {
        @include breakpoint(#{$bp} down) {
          gap: $gap-value;
        }
      }
    }

    .button {
      border: none;
    }
    .material-symbols-outlined {
      font-size: $global-font-size;
      transform: scale(1.5);
    }
    .hiag-shortcut-button {
      padding: $button-padding;
      display: flex;
      gap: 4px;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      color: $anchor-color;

      &:hover {
        color: $anchor-color-hover;
      }

      &:not(.hiag-shortcut-button--with-text) {
        .shortcut-title {
          display: none;
        }
      }

      @include breakpoint(medium up) {
        &.hiag-shortcut-button--with-text {
          position: static;
          &.has-tip {
            position: static;
            opacity: 1;
            color: $anchor-color;
            font-weight: normal;
            padding-right: #{$global-padding * 2};
            border-bottom: none;
          }
        }
      }

      @include breakpoint(medium down) {
        &.hiag-shortcut-button--with-text {
          .shortcut-title {
            display: none;
          }
        }
      }
    }

    .button-transparent {
      padding: $button-padding;
      margin: $button-margin;
      line-height: 1;
      text-align: center;
      font-size: 0.9rem;
    }

    & > .button-transparent,
    & > .button,
    & > * {
      margin-bottom: 0;
    }

    @include breakpoint(medium down) {
      width: 100%;
      flex-wrap: wrap;

      .node--type-search-content {
        width: 100%;
        max-width: 500px;

        .input-group {
          justify-content: right;
        }
      }
    }
  }
}

.node--type-config-special-navigation
  .region:not(.region-left-top-bar):not(.region-right-top-bar) {
  .node--type-config-special-navigation {
    padding: $titlebar-padding;
  }
}
