@import "settings";
.node--view-mode-teaser {
  .node__content {
    gap: $global-margin;
    padding: #{$global-padding / 2} 0;
  }
  .teaser-content {
    display: flex;
    flex-wrap: wrap;

    & > .field--label-inline {
      flex: 1;
      max-width: 50%;
      min-width: 220px;

      & > .field__item,
      & > .field__items > .field__item {
        height: 26px;
        & > * {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 180px;
        }
      }

      .field__items > .field__item:not(:first-child) {
        visibility: hidden;
        position: absolute;
        display: none;
      }
    }

    & > .field:not(.field--label-inline) {
      width: 100%;
    }

    & > .teaser-main-title {
      width: 100%;
      margin: 0;
    }
  }
}

.teaser-label {
  &.flex-container,
  .flex-container {
    gap: 4px;
  }

  * {
    line-height: 1;
    margin: 0;
    padding: 0;
  }
  .teaser-label--date--day-name {
    font-size: $small-font-size;
    padding-top: 4px;
    padding-right: 2px;
  }

  .teaser-label--date--day-num {
    font-weight: bold;
    color: change-color($field-label-color, $alpha: $field-label-alpha);
    display: flex;
    height: 36px;

    &.h3 {
      font-size: $teaser-label-day-number-font-size;
    }
    & > span {
      font-size: 50%;

      &:first-child {
        align-items: flex-start;
      }
      &:last-child {
        align-self: flex-end;
      }

      &.teaser-label--date--day-num-separator {
        align-self: stretch;
        border-left: $teaser-label-separator-border;
        transform: rotate(45deg);
      }
    }
  }

  .teaser-label--date--month-name {
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
  }
}
