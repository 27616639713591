@import "settings";
// BLOCKS
// ------
#block-headernavigationblock {
  @include add-fake-shadow(
    bottom,
    $global-fake-shadow-height,
    $global-fake-shadow-color,
    $global-fake-shadow-transparency
  );
}

#block-footerinhalte {
  @include tabs-underlined-style(120%, $global-std-border, false);
}

// VIEWS
// -----

.view-display-id-block_aktuelles_footer {
  $width-day: 20px;
  $width-month: 40px;
  $gap: 4px;
  a.footer-events {
    strong {
      transform: scale(120%);
      color: change-color($field-label-color, $alpha: $field-label-alpha);
      display: inline-block;
      width: $width-day;
      position: absolute;

      & + span {
        text-transform: uppercase;
        padding-right: $global-padding;
        display: inline-block;
        width: $width-month;
        font-size: 80%;
        position: absolute;
        transform: translateX(#{$width-day + $gap});

        & + span {
          padding-left: $width-day + $width-month + $gap;
          display: block;
        }
      }
    }
  }
}
