@import "settings";

form {
  .container-inline,
  .form--inline {
    display: flex;
    flex-wrap: wrap;
    align-items: end;

    & > *,
    .form-item {
      margin-right: 0.5em;
    }

    input,
    input.button,
    .hiag-form-content-wrapper {
      margin-bottom: 0;
    }
    .cell {
      width: 100%;
    }
    .input-group {
      display: flex;
    }
  }

  .webform-options-display-side-by-side {
    display: flex;
    flex-wrap: wrap;
    gap: $global-margin / 2;

    div {
      margin-right: 0;
    }
  }

  @media (max-width: 600px) {
    .webform-options-display-buttons
      label.webform-options-display-buttons-label {
      width: 100%;
      display: block;
      margin: 0;
    }
  }
  .details-wrapper.accordion-content {
    padding-left: 0;
    padding-right: 0;
  }

  :is(.container-inline, .form--inline) + * {
    margin-top: $global-margin;
  }

  .hiag-form-content-wrapper {
    margin-bottom: $global-margin;
    & > :where(button, input, optgroup, select, textarea) {
      margin-bottom: 0;
    }

    & > .form-textarea-wrapper > .ck {
      border: $input-border;
      border-radius: $input-radius;
    }
  }

  :is(
      .webform-options-display-side-by-side,
      .webform-options-display-buttons,
      td
    )
    .hiag-form-content-wrapper {
    margin-bottom: 0;
    .webform-options-display-buttons-wrapper {
      margin: 0;
    }
  }

  @mixin required() {
    &:after {
      content: "*";
      position: relative;
      color: $alert-color;
      font-size: 90%;
      font-weight: bold;
      padding-left: 0.2em;
      padding-right: 0.2em;
      top: -0.3em;
    }
  }

  .webform-required {
    margin-bottom: $global-margin;

    .form-required {
      @include required();
    }
  }

  .hiag-form-label-wrapper,
  .hiag-form-content-wrapper {
    & > label {
      @include hiag-label($field-label-color, $field-label-alpha);

      &.form-required {
        @include required();
      }
    }
  }

  .form-wrapper.wrapper-container:not(.form-actions) {
    border: $form-container-border;
    padding: $form-container-padding;
    margin: $form-container-margin;
  }

  .help-text {
    margin-top: 0;
  }

  .input-group,
  .hiag-form-content-wrapper.input-group-container {
    margin-bottom: 0;
  }

  select:not([multiple]) {
    padding: $input-padding;
    height: auto;
    line-height: 1.15;
  }

  section.webform-section {
    margin-bottom: 2 * $global-margin;
  }

  label.option {
    &:after {
      content: none;
    }
  }

  hr {
    &.webform-horizontal-rule--gradient {
      background-image: linear-gradient(
        to right,
        change-color($form-rule-color, $alpha: 0),
        $form-rule-color,
        change-color($form-rule-color, $alpha: 0)
      );
    }

    &[class*="webform-horizontal-rule"] {
      color: $form-rule-color;
      border-color: $form-rule-color;
      &:before {
        border-color: $form-rule-color;
      }
    }
  }

  .tableselect-sort :where(th, td):first-child {
    width: 100px;
  }
}

.captcha-type-challenge--image .captcha__element {
  display: block;

  #edit-captcha-image-wrapper {
    display: flex;
    align-items: center;
    gap: $global-margin;
  }
}

// Elements

.select2-container--default {
  .select2-selection--single,
  .select2-selection--multiple {
    border: $input-border;
    padding: $input-padding;
    height: auto;

    .select2-selection__rendered {
      line-height: $global-lineheight;
      font-size: $input-font-size;
      color: $input-color;
      padding: 0;
    }

    ul.select2-selection__rendered {
      margin: 0;
      line-height: $global-lineheight;
    }
  }

  .select2-selection--multiple {
    display: flex;
    flex-wrap: wrap;

    ul.select2-selection__rendered {
      display: flex;
      flex-wrap: wrap;
    }
    ul.select2-selection__rendered > li.select2-selection__choice {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px 8px;
      margin: 4px;
      height: $checkbox-size;
      gap: 4px;
      border-radius: $button-radius;
      border: $button-border;
      background-color: $light-gray;
      button {
        border-radius: 50%;
        position: static;
        border: none;
        background: var(--alert-color);

        &:hover,
        &:focus,
        &:active {
          background: var(--alert-color-darken-2);
        }
        span {
          color: $white;
        }
      }
      .select2-selection__choice__display {
        max-width: 200px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    textarea.select2-search__field {
      height: auto;
    }
  }

  &.select2-container--focus {
    .select2-selection--single,
    .select2-selection--multiple {
      border: $input-border-focus;
    }
  }
}

.checkbox-container {
  display: inline-flex;
  align-items: center;
  height: $checkbox-size;
  position: relative;
  padding-left: $checkbox-size + $checkbox-padding;
  cursor: pointer;
  font-size: $form-label-font-size;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      background-color: $checkbox-background-checked;

      &:after {
        display: block;
      }
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: $checkbox-size;
    width: $checkbox-size;
    background-color: $checkbox-background;
    border-radius: $checkbox-radius;

    &:after {
      content: "";
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      width: $checkbox-size / 3;
      height: $checkbox-size / 1.5;
      border: solid $checkbox-color;
      border-width: 0 $checkbox-checkmark-width $checkbox-checkmark-width 0;

      transform: translate(100%, 20%) rotate(45deg);
    }
  }

  &:hover input ~ .checkmark {
    filter: brightness(0.8);
  }
}

.radio-container {
  display: inline-flex;
  align-items: center;
  height: $radio-size;
  position: relative;
  padding-left: $radio-size + $radio-padding;
  cursor: pointer;
  font-size: $form-label-font-size;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .radiomark {
      &:after {
        display: block;
      }
    }
  }

  .radiomark {
    position: absolute;
    top: 0;
    left: 0;
    height: $radio-size;
    width: $radio-size;
    background-color: $radio-background;
    border-radius: 50%;

    &:after {
      content: "";
      display: none;
      position: absolute;
      left: $radio-checked-border-width;
      top: $radio-checked-border-width;
      background-color: $radio-background-checked;
      height: calc(#{$radio-size} - #{$radio-checked-border-width * 2});
      width: calc(#{$radio-size} - #{$radio-checked-border-width * 2});
      border-radius: 50%;
    }
  }

  &:hover input ~ .radiomark {
    filter: brightness(0.8);
  }
}

.webform-likert-table .radio-container {
  padding-left: $radio-size;
}
@include breakpoint($table-stack-breakpoint #{down}) {
  table.webform-likert-table {
    tr {
      display: flex;
      flex-wrap: wrap;

      td:first-child {
        width: 100%;
      }
    }
    @for $i from 2 through 7 {
      &[data-likert-answers-count="#{$i}"] tr td:not(:first-child) {
        width: 100% / $i !important;
      }
    }
  }
}

.range-input-container.input-group-field {
  border: $input-border;
  display: flex;
  align-items: center;
  & > input {
    margin: $global-margin;
    width: 100%;
  }
}

.input-group-label > .hiag-form-item-wrapper {
  margin-top: $global-margin;
  margin-bottom: $global-margin;
}

.form-item-color .hiag-form-content-wrapper {
  border: $input-border;
  border-radius: $input-radius;
  display: flex;
  overflow: hidden;

  input.form-color {
    margin-bottom: 0;
    border: none;
    &:not([type="color"]) {
      flex: 1;
      &[class*="small"] {
        padding: $input-padding / 2;
      }
      &[class*="medium"] {
        padding: $input-padding;
      }
    }
    &[class*="small"] {
      width: 24px;
      height: 24px;
    }
    &[class*="medium"] {
      width: 32px;
      height: 32px;
    }
    &[class*="large"] {
      width: 40px;
      height: 40px;
    }
  }
}

[role="dialog"] {
  z-index: $reveal-zindex;
  border: $reveal-border;
}

label.file-input-label {
  @include button(
    false,
    $button-background,
    $button-background-hover,
    $button-color,
    "solid"
  );
  margin-bottom: 0;
  display: inline-block;
  input[type="file"] {
    position: absolute;
    display: none;
    visibility: hidden;
    opacity: 0;
  }

  &:invalid ~ span {
    color: $black;
  }
  &:valid ~ span {
    color: $white;
  }
}

.button.webform-file-button + label.file-input-label {
  position: absolute;
  display: none;
  visibility: hidden;
  opacity: 0;
}

.form-managed-file {
  .button {
    margin-bottom: 0;
  }

  .form-type-checkbox:first-of-type {
    margin-top: $global-margin;
  }
}

form table {
  @include breakpoint($table-stack-breakpoint #{down}) {
    td {
      width: 100% !important;
    }

    tr.draggable {
      padding-top: $global-margin / 2;
      padding-bottom: $global-margin / 2;
    }
  }
  tr.draggable {
    transition: transform 0.2s;
    transform: translateY(0rem);
    &.sortable--up {
      transform: translateY(0.5rem);
    }
    &.sortable--down {
      transform: translateY(-0.5rem);
    }
    a.tabledrag-handle {
      display: flex;
      align-items: center;
      justify-content: center;
      height: auto;
      margin-left: 0;
      -webkit-user-drag: none;
      user-drag: none;

      .handle {
        width: $table-draghandle-size;
        height: $table-draghandle-size;
        margin: 0;
        padding: 0;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 0 0;
        margin-right: $global-margin / 2;
      }
    }
  }

  &.sticky-enabled {
    thead.table-header--stuck {
      border-radius: $global-radius;
      position: relative;
      z-index: 1000;
      background: transparent;

      & > tr {
        position: relative;
        z-index: 1001;
      }
      &:before {
        content: "";
        width: 100%;
        background: $white;
        box-shadow: $global-shadow-small;
        height: calc(100%);
        border: $table-border;
        border-radius: $global-radius;
        left: 0;
        bottom: 0;
        position: absolute;
      }
    }
  }

  &[class*="tableselect"] tr > td:first-child .hiag-table-stacked-content {
    display: flex;
    align-items: center;

    .hiag-form-content-wrapper {
      margin-bottom: 0;
    }
  }

  .webform-multiple-sort-weight,
  .table-sort-weight,
  td.tabledrag-hide {
    width: 4em;
  }

  td .hiag-table-stacked-content > fieldset {
    border: none;
    margin: 0;
    padding: 0;
  }
}

fieldset.webform-term-checkboxes-scroll > .fieldset-wrapper {
  margin: -1 * $fieldset-padding;
  border: none;
}

ul.ui-menu {
  background-color: $white;
}

.hiag-form-content-wrapper:not(:first-child)
  .tabledrag-toggle-weight-wrapper:first-child {
  height: 0;
  button {
    transform: translateY(#{-100% * 1.45});
  }
}

.form-item:is(.form-type-datetime, .form-item.form-type-datelist) {
  .container-inline {
    display: flex;

    div,
    label {
      margin-right: 0;
      display: block;
    }

    .form-item:not(:first-child) :where(input, select) {
      border-left: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      &:focus {
        border-right: $input-border;
        border-left: $input-border-focus;
      }
    }
    .form-item:not(:last-child) :where(input, select) {
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      &:focus {
        border-left: $input-border;
        border-right: $input-border-focus;
      }
    }

    .form-item :where(input, select) {
      box-shadow: none;
      &:focus {
        border-top: $input-border;
        border-bottom: $input-border;
      }
    }
  }
}

.webform-scale {
  display: block;
  .webform-scale-options {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: $global-margin / 2 0;
    justify-content: space-between;

    label.option.radio-container > span:first-child {
      width: 0;
    }

    @for $i from 2 through 7 {
      @include breakpoint(medium down) {
        justify-content: start;
        &[data-scale-resposive-divisor="#{$i}"] {
          .webform-scale-option {
            display: flex;
            justify-content: center;
            width: 100% / $i;
          }
        }
      }
    }

    .hiag-form-content-wrapper {
      margin-bottom: 0;
    }
  }
}

.touchevents .draggable td {
  padding: $table-padding;
}
