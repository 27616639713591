@import "settings";

.region-footer {
  .dropdown.menu > li > a {
    padding: $menu-items-padding;
  }
  .is-dropdown-submenu {
    background-color: $black;
    transform: translateY(-5px);
    border-radius: 5px;
  }

  .menu-item.is-dropdown-submenu-parent > a::after {
    transform-origin: 75%;
    transform: scale($footer-usermenu-font-size) rotate(180deg);
  }

  nav.menu--footer {
    display: flex;
    align-items: center;
  }
}
