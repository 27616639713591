@import "settings";

.node--type-page {
  &.node--view-mode-full {
    .field--name-field-image {
      width: 100%;
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .page-sub-content {
    margin-bottom: $global-margin;
  }
}
