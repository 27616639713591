@import "settings";

// Basic Settings for all but top-bar
.region:not(.region-left-top-bar):not(.region-right-top-bar) {
  .node--type-header-content {
    .hiag-branding-link {
      flex-direction: column;
      align-items: center;
      justify-content: center;

      * {
        min-height: unset;
      }

      .hiag-logo-container .field--name-field-logo {
        height: $topbar-logo-start-height;
        width: $topbar-logo-start-height;
      }
    }

    h1.field--name-field-website-name {
      font-size: 2rem;
      display: block;
      padding-left: 0.25rem;
    }
  }

  // Exeption Contents:
  .node--type-config-special-navigation {
    .node--type-header-content .hiag-branding-link {
      flex-direction: row;
    }
    .node__content > *:last-child {
      .node--type-header-content .hiag-branding-link {
        flex-direction: row-reverse;

        .hiag-main-title-container {
          h1,
          h6 {
            text-align: right;
          }
        }
      }
    }
  }
}

/*
REGIONAL NODE TYPE SETTINGS
---------------------------
*/

.region-left-top-bar,
.region-right-top-bar {
  .node--type-header-content {
    .hiag-branding-link {
      color: $body-font-color;

      &:hover,
      &:active,
      &:visited {
        color: $body-font-color;
      }
    }

    .hiag-logo-container .field--name-field-logo {
      height: $topbar-logo-start-height;
      width: $topbar-logo-start-height;
      transition: height $topbar-transition-speed ease,
        width $topbar-transition-speed ease;

      img {
        object-fit: contain;
        max-height: 100%;
      }
    }

    .hiag-main-title-container {
      h1 {
        word-break: keep-all;
        white-space: nowrap;
        width: max-content;
      }

      @media screen and (max-width: 1200px) {
        h1 {
          font-size: 2.5rem;
        }
      }

      @media screen and (max-width: 1080px) {
        h1 {
          font-size: 2rem;
        }
      }

      h1 {
        transition: font-size $topbar-transition-speed ease,
          transform $topbar-transition-speed ease;
      }

      h6 {
        transition: height $topbar-transition-speed ease,
          opacity $topbar-transition-speed ease;
      }
    }

    @media screen and (max-width: 1020px) {
      .hiag-logo-container .field--name-field-logo {
        height: $topbar-logo-end-height;
        width: $topbar-logo-end-height;
      }

      .hiag-main-title-container {
        h1 {
          font-size: calc(#{$topbar-logo-end-height} / 2);
          transform: translateY(10px);
        }

        h6 {
          display: none;
        }
      }
    }
  }
}
@if not $topbar-full-hide {
  .is-stuck .region-left-top-bar,
  .is-stuck .region-right-top-bar {
    .node--type-header-content {
      .hiag-logo-container .field--name-field-logo {
        height: $topbar-logo-end-height;
        width: $topbar-logo-end-height;
      }

      .hiag-main-title-container {
        h1 {
          font-size: calc(#{$topbar-logo-end-height} / 2);
          transform: translateY(10px);
        }

        h6 {
          opacity: 0;
          height: 0px;
        }
      }
    }
  }
}

.region-right-top-bar {
  .node--type-header-content {
    .hiag-branding-link {
      flex-direction: row-reverse;
      .hiag-main-title-container {
        h1,
        h6 {
          margin-left: auto;
        }
      }
    }
  }
}
