@import "settings";

.region-breadcrumb {
  padding-top: $global-margin;
  margin-bottom: $global-margin;

  .hiag-breadcrumb-item:not(:last-child)::after {
    font-family: "Material Symbols Outlined";
    content: "\ea50" !important;
  }
}
