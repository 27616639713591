@import "settings";

.menu--main .menu li:last-child > span {
  display: none;
}

.hiag-search-link {
  .button {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    padding-bottom: 0;
  }

  .node--type-search-content > form {
    width: 100%;

    .input-group-field[type="search"] {
      max-width: unset;
      min-width: unset;
    }
  }

  .dropdown-pane {
    width: calc(100vw - 32px);
    max-width: $global-width;

    .hiag-dropdown-navigation-search {
      width: 100%;
      margin: auto;
      max-width: #{map-get($breakpoints, "medium")};
    }
  }
}

/*
REGIONAL NODE TYPE SETTINGS
---------------------------
*/
.region-right-top-bar,
.region-left-top-bar {
  .hiag-search-link {
    width: 0px;
    max-width: min-content;
    opacity: 0;
    transition: width 0.4s 0.25s, opacity 0.4s;

    @media screen and (max-width: 1020px) {
      width: 60px;
      max-width: min-content;
      opacity: 1;
      transition: width 0.4s, opacity 0.4s 0.25s;
    }
  }
}

.is-stuck {
  .region-right-top-bar,
  .region-left-top-bar {
    .hiag-search-link {
      width: 60px;
      max-width: min-content;
      opacity: 1;
      transition: width 0.4s, opacity 0.4s 0.25s;
    }
  }
}

.region-bottom-top-bar {
  .hiag-search-link .dropdown-pane {
    width: 100%;
  }
}
