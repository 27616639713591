@import "settings";

.block-type-flying-menu {
  animation-name: enter;
  animation-duration: 0.8s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  position: fixed;
  z-index: 2000;
  right: 0;
  top: 33%;

  & > h2 {
    visibility: hidden;
    display: none;
  }

  ul.flying-menu-links--wrapper {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: $flying-menu-gap;

    li.flying-menu--item {
      z-index: 2000;

      &:hover {
        z-index: 3000;
      }

      & > :where(.flying-menu--block-container, .flying-menu--link) {
        transition: width 0.4s;
        gap: 0.525rem !important;
        display: flex;
        justify-content: right;
        background: $flying-menu-background;
        padding: $global-padding;
        box-shadow: $flying-menu-shadow;
        border: $flying-menu-border;
        border-right: none;
        border-radius: $global-radius 0 0 $global-radius;

        .flying-menu--text {
          transition: width 0.4s 0.4s;
          width: 0px;
          overflow: hidden;
          white-space: nowrap;
          color: $flying-menu-color;
        }

        .flying-menu--block {
          transition: opacity 0.4s;
          opacity: 0;
          position: absolute;
          left: 0;
          bottom: 0;
          max-width: 100%;
          min-width: 200px;
          height: 0;
          display: flex;
          flex-direction: column;

          & > * {
            background-color: $flying-menu-block-background;
            box-shadow: $flying-menu-shadow;
            border-radius: 0 0 $global-radius $global-radius;
            transition: visibility 0.4s;
            visibility: hidden;
          }

          & > *:not(.card-section) {
            padding: $global-padding;
          }
        }

        &.hiag-external-link::before {
          content: none !important;
        }

        &:hover,
        &.touched {
          background: $flying-menu-background-hover;

          .flying-menu--text {
            width: $flying-menu-width;
            transition: width 0.4s;
          }
          .flying-menu--block {
            opacity: 1;
            transition: opacity 0.4s 0.4s;

            & > * {
              visibility: visible;
              transition: visibility 0s;
            }
          }
        }

        .flying-menu--icon {
          transform-origin: 50% 0%;
          transform: scale($flying-menu-icon-scale);
          color: $flying-menu-color;
        }
      }

      & > .flying-menu--block-container {
        position: relative;
        transition: width 0.4s border-radius 0.4s;

        &:hover,
        &.touched {
          border-radius: $global-radius 0 0 0;
          transition: border-radius 0.4s 0.4s;
        }
      }
    }
  }

  @keyframes enter {
    0% {
      transform: translateX(100px);
    }
    100% {
      transform: translateX(0px);
    }
  }
}

@mixin hiag_icon_replace($path) {
  width: 16px;
  position: relative;
  color: transparent !important;
  &:before {
    content: "";
    mask-image: url($path);
    mask-repeat: no-repeat;
    position: absolute;
    width: 100%;
    height: 100%;
    mask-size: 98%;
    background: $flying-menu-color;
  }
}

.icon--hiag-facebook {
  @include hiag_icon_replace(
    "/themes/hiag_base_theme/images/icons/facebook_logo_hiag.png"
  );
}

.icon--hiag-instagram {
  @include hiag_icon_replace(
    "/themes/hiag_base_theme/images/icons/instagram_logo_hiag.png"
  );
}

.icon--hiag-teamviewer {
  @include hiag_icon_replace(
    "/themes/hiag_base_theme/images/icons/teamviewer_logo_hiag.png"
  );
}
