@import "settings";

@mixin shortcuts-flex {
  display: flex;
  justify-content: $shortcuts-justify;
  flex-wrap: wrap;
  gap: $shortcut-gap;
  margin: $global-margin;

  @if ($shortcuts-justify == space-between) {
    @include breakpoint(medium down) {
      justify-content: center;
    }
  }
}

.hiag-shortcuts-view {
  .view-content {
    @include shortcuts-flex;
  }

  .hiag-shortcut-button {
    &.button {
      padding: $shortcut-padding;
      box-shadow: $shortcut-shadow;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .field--name-field-shortcut-link {
      a {
        font-size: 0.8rem;
        white-space: nowrap;
        overflow-wrap: normal;
        word-break: keep-all;
        opacity: 1;
        color: $white;
        line-height: calc(#{2 * $shortcut-size} + #{2.5 * $shortcut-padding});
      }
    }
  }
}

.region-right-sidebar {
  .hiag-shortcuts-view {
    padding-top: $global-padding;
    padding-bottom: $global-padding;
  }
}

.region-directlinks {
  .hiag-shortcuts-view {
    .view-content {
      margin: $shortcut-view-margin;
    }
  }
}

@if $shortcuts-justify == space-between {
  .region-right-sidebar {
    height: 100%;

    .hiag-shortcuts-view {
      height: 100%;
      .view-content {
        height: 100%;

        .views-row {
          flex: 1;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .hiag-views-block-style-hiag-shortcuts {
      height: 100%;
      & > div {
        height: 100%;
      }
    }
  }
}

.hiag-shortcuts-custom {
  .view-content {
    @include shortcuts-flex;

    .views-row a {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: $primary-color;
      padding: $shortcut-padding;

      img {
        height: $shortcut-size;
        width: $shortcut-size;
      }

      .shortcut-title-wrapper {
        position: relative;
        width: 0px;
        height: 0px;
        display: flex;

        justify-content: center;

        .shortcut-title {
          overflow: visible;
          color: color-pick-contrast($primary-color, $black $white);
          text-align: center;
          white-space: nowrap;
        }
      }
    }
  }
}
