.orbit {
  @if $orbit-bullet {
    @if $orbit-bullet-inside {
      @include orbit-bullets-inside;
    }
  } @else {
    .orbit-bullets {
      display: none;
    }
  }
}

.hiag-layout-container {
  @each $size, $value in $orbit-backdrop-min-height {
    @if $size == "default" {
      --current-orbit-backdrop-height: #{$value};
    } @else {
      @include breakpoint($size down) {
        --current-orbit-backdrop-height: #{$value};
      }
    }
  }
}

.hiag-layout-container:not(.is-front-page) {
  .orbit img.orbit-image.image-style-main-banner {
    @if ($orbit-backdrop-not-front-clamp-minimum) {
      height: var(--current-orbit-backdrop-height);
    }
    object-fit: cover;
  }
}

.hiag-layout-container.is-front-page {
  .orbit img.orbit-image.image-style-main-banner {
    object-fit: cover;
    transform: scale(102%);
    min-height: var(--current-orbit-backdrop-height);
  }
}

.hiag-orbit-reveal.reveal {
  background-color: $image-gallery-reveal-background;
}

.hiag-orbit-reveal-overlay.reveal-overlay {
  background-color: $image-gallery-reveal-overlay;
}
