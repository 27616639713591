@import "settings";

.node--type-person {
  &.node--view-mode-hiag-card {
    @include card-container(
      $hiag-person-background-color,
      $hiag-person-font-color,
      $global-margin,
      $hiag-person-card-border,
      $global-radius,
      $hiag-person-card-shadow
    );
    position: relative;

    .hiag-person-header.card-divider {
      background-color: $hiag-person-card-divider-background-color;
      color: $hiag-person-card-divider-font-color;
      align-items: center;
      padding: 0;
    }

    @if $hiag-person-button-section == "content" {
      .hiag-card-content-section {
        position: absolute;
        top: $global-margin;
        #{$hiag-person-button-section-position}: $global-margin;
      }

      .hiag-card-divider {
        display: none;
        visibility: hidden;
      }
    }

    @if $hiag-person-button-section == "subcontent" {
      .hiag-card-subcontent-section {
        position: absolute;
        top: $global-margin;
        #{$hiag-person-button-section-position}: $global-margin;
      }

      .hiag-card-divider {
        display: none;
        visibility: hidden;
      }
    }

    @each $class in $hiag-person-card-button-field-classes {
      .#{$class} {
        visibility: hidden;

        a:is(.hiag-phone-link, .hiag-mail-link, .hiag-external-link) {
          width: $hiag-person-button-width;

          &:before {
            visibility: visible;
            padding: $button-padding;
            background: $button-background;
            color: $button-color;
            border: $button-border;
            border-radius: $button-radius;
            margin: $button-margin;
          }
        }

        a:not(.hiag-phone-link):not(.hiag-mail-link):not(.hiag-external-link) {
          visibility: visible;
          padding: $button-padding;
          background: $button-background;
          color: $button-color;
          border: $button-border;
          border-radius: $button-radius;
          margin: $button-margin;

          &:before {
            content: none;
          }
        }
      }
    }
  }

  &.node--view-mode-hiag-person {
    .node__content {
      display: flex;
      flex-wrap: wrap;
      gap: $global-margin;
      padding: $global-padding 0;

      @include breakpoint(small down) {
        flex-direction: column-reverse;

        .person-contact-block {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          gap: $global-margin;
          & > * {
            flex: 1;
            min-width: 200px;
            height: min-content;
            margin: 0;
          }
        }
      }
    }

    & > h4 {
      width: 100%;
      border-bottom: $global-std-border;
    }

    .field--name-body {
      position: relative;
      overflow-y: hidden;
      transition: height map-get($transition-speed, "base");

      .button.field--name-body--expand-more-button {
        position: absolute;
        right: 0;
        bottom: 0;
        transition: transform map-get($transition-speed, "base");
      }

      &:before {
        position: absolute;
        bottom: 0;
        right: 0;
        height: 50px;
        width: 100%;
        transition: opacity map-get($transition-speed, "base");
      }
      &.closed {
        height: 100px;
        &:before {
          content: "";

          opacity: 1;
          background: $main-content-background;
          background: -moz-linear-gradient(
            0deg,
            $main-content-background 0%,
            change-color($main-content-background, $alpha: 0) 100%
          );
          background: -webkit-linear-gradient(
            0deg,
            $main-content-background 0%,
            change-color($main-content-background, $alpha: 0) 100%
          );
          background: linear-gradient(
            0deg,
            $main-content-background 0%,
            change-color($main-content-background, $alpha: 0) 100%
          );
        }
      }

      &.expanded {
        height: calc(var(--body-inner-height) + 50px);
        &:before {
          content: "";
          opacity: 0;
        }
      }
    }

    .person-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: $global-margin;
    }

    .field--label-inline {
      display: flex;

      .field__label {
        flex: 1;
      }

      .field__items,
      & > .field__item {
        flex: $field-label-inline-flex;
      }

      @include breakpoint($field-label-inline-breakpoint down) {
        flex-direction: column;
      }
    }

    .field--name-field-function {
      transform: translateY(-#{$global-margin});
    }
  }

  &.node--view-mode-full {
    .field--name-field-function {
      transform: translateY(-#{$global-margin});
    }

    .page-sub-content {
      @include breakpoint(#{$view-mode-full-content-stacked-breakpoint} up) {
        .field--name-field-portrait {
          img {
            width: 100%;
            max-width: 250px;
          }
        }
      }
    }
  }
}
