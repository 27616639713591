@import "settings";

// Oribit Mixins

@mixin orbit-bullets-inside {
  /*
  Include This mixin in an orbit and the bullets will be inside the canvas for the
  particular orbit.
  */
  .orbit-bullets-container {
    z-index: $orbit-control-zindex;
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0px;
    overflow: visible;
    display: flex;
    justify-content: center;

    .orbit-bullets {
      width: max-content;
      display: flex;
    }
  }
}

@mixin mdi-icon($codepoint) {
  content: $codepoint;
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

@mixin set-icon($iconName) {
  $icons: (
    "expand_more": "\e5cf",
    "download": "\f090",
    "links": "\e157",
    "info": "\e88e",
  );

  &.hiag-css-icon:after {
    content: map-get($icons, $iconName);
  }
}

@mixin hiag-table($scroll, $stacked, $stacked-breakpoint, $stacked-label) {
  @if ($scroll) {
    @include table-scroll;
  }

  .hiag-table-stacked-header {
    display: none;
  }

  @if ($stacked) {
    @include breakpoint($stacked-breakpoint #{down}) {
      .is-empty {
        display: none;
      }

      @include table-stack($show-header-for-stacked);

      .hiag-table-stacked-header {
        @if $stacked-label {
          display: unset;
        }
        font-size: $field-label-size;
        color: $field-label-color;
      }
    }
  }
}

@mixin hiag-label($color, $alpha) {
  font-weight: bold;
  color: change-color($color, $alpha: $alpha);
  padding-bottom: 4px;
}

@mixin add-fake-shadow(
  $shadow-align,
  $shadow-height,
  $shadow-color,
  $shadow-transparency
) {
  position: relative;
  overflow: visible;

  // --> add shadow
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: $shadow-height;
    background: transparent;

    @if $shadow-align == bottom {
      bottom: #{-1 * $shadow-height};
      background: linear-gradient(
        0deg,
        #{fade-out($shadow-color, 1)} 0%,
        #{fade-out($shadow-color, $shadow-transparency)} 100%
      );
    }
    @if $shadow-align == top {
      top: #{-1 * $shadow-height};
      background: linear-gradient(
        180deg,
        #{fade-out($shadow-color, 1)} 0%,
        #{fade-out($shadow-color, $shadow-transparency)} 100%
      );
    }
    z-index: 5;
    left: 0;
  }
}

@mixin tabs-underlined-style($title-size-scale, $underline-border, $uppercase) {
  .tabs {
    .tabs-title {
      a {
        font-size: $title-size-scale; //120%
        font-weight: bold;
        @if $uppercase {
          text-transform: uppercase;
        }

        letter-spacing: 0.1rem;

        &:after {
          content: attr(data-text);
          content: attr(data-text) / "";
          @if $uppercase {
            text-transform: uppercase;
          }
          height: 0;
          visibility: hidden;
          overflow: hidden;
          user-select: none;
          pointer-events: none;
          font-weight: bold;
          letter-spacing: 0.1rem;
          display: block;
        }

        @include breakpoint(medium down) {
          font-size: 100%;
          font-weight: bold;
          @if $uppercase {
            text-transform: uppercase;
          }
          letter-spacing: 0.05rem;
        }
        &:after {
          letter-spacing: 0.05rem;
        }
      }
      &.is-active a {
        border-bottom: $underline-border; //3px solid $black;
      }
      &:not(.is-active) a {
        font-weight: normal;

        &:after {
          font-weight: bold;
        }
      }
      a:hover {
        font-weight: bold;
      }
    }
  }
}

@mixin auto-regional-colors($regional-background) {
  background-color: $regional-background;
  @if lightness($regional-background) >= 50 {
    --current-std-border-color: #{map-get($global-border-colors, light)};
  } @else {
    --current-std-border-color: #{map-get($global-border-colors, dark)};
  }
}

@mixin tabs-to-buttons() {
  ul.hiag-tabs,
  .view-content > .tabs {
    display: flex;
    flex-wrap: wrap;
    border-color: transparent;
    gap: $buttongroup-spacing;
    .tabs-title {
      a {
        @include button();
        margin-bottom: 0;
      }
    }
    .tabs-title:not(.is-active) {
      a {
        background-color: transparent;
        border: 1px solid $button-background;
        color: $button-background;

        &:hover,
        &:focus {
          background-color: scale-color(
            $button-background,
            $lightness: $button-hollow-hover-lightness
          );
        }
      }
    }
  }
  .tabs-content {
    border-color: transparent;
  }
}

@mixin content-use-flex-labels-inline() {
  .field--label-inline {
    display: flex;

    .field__label {
      flex: 1;
      min-width: 150px;
    }

    .field__items,
    & > .field__item {
      flex: $field-label-inline-flex;
    }

    @include breakpoint($field-label-inline-breakpoint down) {
      flex-direction: column;
    }
  }
}
