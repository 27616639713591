.views-table {
  table-layout: fixed;
  display: table;

  th {
    width: 100%;
  }
  th.hiag-dropdown-table-header,
  th.hiag-reveal-table-header {
    width: 90px;
    text-align: right;
  }
  .views-field-edit-node.is-empty {
    display: none;
  }

  .views-align-right > .hiag-table-stacked-content,
  .views-align-right > .hiag-table-stacked-content {
    text-align: right;
    & > a {
      justify-content: right;
    }
  }
}
