@import "settings";

.field--name-field-people-list {
  &.field--items-mode-hiag-card > .field__items {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: $global-margin;

    & > .node--view-mode-hiag-card {
      max-width: 100%;
      width: 100%;
      @include breakpoint("medium") {
        --gutt: calc(0.5 * var(--global-margin));
        max-width: calc(50% - var(--gutt));
      }
      @include breakpoint("large") {
        --gutt: calc(0.66 * var(--global-margin));
        max-width: calc(33% - var(--gutt));
      }
      @include breakpoint("xlarge") {
        --gutt: calc(0.75 * var(--global-margin));
        max-width: calc(25% - var(--gutt));
      }
    }
  }
}
