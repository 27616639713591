@import "settings";

.node--type-article {
  &.node--view-mode-hiag-card {
    .hiag-article-header {
      display: flex;
      flex-direction: column-reverse;
    }

    .field--name-title {
      font-size: $article-card-title-size;
    }

    .hiag-article-content {
      display: flex;
      flex-direction: column;
      gap: $global-margin;

      .hiag-article-content-section {
        display: flex;
        justify-content: space-between;

        .field + .field {
          margin-top: 0;
        }
      }

      .hiag-article-separator {
        border-top: $hiag-person-body-seperator-border;
        width: 100%;
      }
    }
  }
}
