.node--view-mode-hiag-image-gallery {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h6 {
    margin-bottom: 0;
    margin-top: calc(var(--global-margin) / 2);
  }
  .field--name-title {
    font-weight: bold;
  }

  .image-gallery-date {
    font-size: 90%;
  }
}
