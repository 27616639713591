@import "settings";

.node--view-mode-hiag-card {
  .hiag-card-image {
    width: 100%;
    height: auto;

    * {
      width: 100%;
      height: auto;
    }
  }

  .hiag-card-header {
    flex-direction: column;

    .field--type-string {
      color: $hiag-person-card-divider-font-color;
    }

    .field--name-title {
      font-size: 120%;
    }
  }

  .hiag-card-divider:not(:first-child):not(:last-child) {
    margin: $global-margin 0;
    border-top: $hiag-person-body-seperator-border;
    height: 0px;
    width: 100%;
  }
}
