@import "settings";

.region-right-off-canvas {
  .card {
    background-color: transparent;
    box-shadow: none;
    border-color: transparent;
  }
}

@if #{$drilldown-background} == #{$offcanvas-background} {
  .off-canvas .region .is-drilldown .drilldown {
    background: transparent;
    a,
    .is-drilldown-submenu {
      background: transparent;
    }
  }
}
