@import "settings";

.hiag-top-bar-wrapper {
  z-index: 1000;

  * {
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
  }

  .title-bar {
    align-items: flex-end;
    box-shadow: $titlebar-shadow;

    @include breakpoint(small down) {
      align-items: center;
    }
  }

  .title-bar-right,
  .title-bar-left {
    position: relative;
  }

  .hiag-title-bar-bottom-controller {
    position: absolute !important;
    height: 0px !important;
    width: 0px !important;
    overflow: visible !important;
    bottom: 0 !important;
    left: 0 !important;
  }
}
