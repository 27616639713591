@import "settings";

.region-footer {
  justify-content: space-between;
  margin-left: 0;
  margin-right: 0;
  background: $footer-usermenu-background;
  a {
    font-size: $footer-usermenu-font-size;
  }
  * {
    color: fade-out($footer-usermenu-color, 0.5);

    &:hover {
      color: $footer-usermenu-color;
    }
  }

  .dropdown.menu > li.is-dropdown-submenu-parent > a::after {
    border-color: fade-out($footer-usermenu-color, 0.5) transparent transparent;
  }
}
