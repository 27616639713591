@import "settings";

.hiag-shortcut-button {
  @include button-style(
    $shortcut-background,
    $shortcut-background-hover,
    $shortcut-color
  );
  position: relative;
  cursor: pointer;

  * {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .material-symbols-outlined {
    font-size: $shortcut-size;
    font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
  }

  .field--name-field-custom-icon {
    height: $shortcut-size;
    width: $shortcut-size;
  }

  .shortcut-title {
    max-width: $shortcut-size;
    height: 12px;
    word-break: normal;
  }
}

@include breakpoint(large) {
  .hiag-shortcut-tooltip-responsive {
    display: none !important;
  }
}
