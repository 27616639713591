@import "settings";

.region-left-top-bar {
  position: relative;
  :not(.button-group) {
    .button {
      margin-bottom: 0;
    }
  }

  .card {
    @include card-container(
      $title-bar-card-background-color,
      $title-bar-card-font-color,
      0px,
      $title-bar-card-border,
      $global-radius,
      $title-bar-card-shadow
    );

    .card-divider {
      background-color: $title-bar-card-divider-background-color;
      color: $title-bar-card-divider-font-color;
    }
  }

  @if $topbar-logo-region == right {
    & > :first-child:not(.menu--main:last-child) {
      position: absolute;
      width: 100%;
      top: calc(#{$topbar-logo-start-height} / -2);
      opacity: 1;
      transition: opacity 0.4s ease, top 0.4s ease;
    }
    &.card {
      overflow: visible;
    }
    & > * {
      position: relative;
      z-index: 5;
    }
    &:before {
      content: "";
      position: absolute;
      width: 100vw;
      height: 100%;
      left: 0;
      top: 0;
      transform: scale(1.05, 1.2);
      background-color: $title-bar-card-background-color;
    }

    @media screen and (max-width: 1020px) {
      & > :first-child:not(.menu--main:last-child) {
        display: none;
      }
    }
  }
}

@if $topbar-logo-region == right {
  .is-stuck .region-left-top-bar {
    & > :first-child:not(.menu--main:last-child) {
      opacity: 0;
      top: calc(#{$topbar-logo-start-height} / -1);
    }
  }
}
