@import "settings";
.hiag-menu-local-tasks-tabs {
    margin-bottom: $global-margin;
    display: flex;
    border-color: #bebebe;

    .tabs-title {
        flex: 1;

        a {
            text-align: center;
            color: #bebebe;
        }
    }
    
    .tabs-title>a:hover {
        background-color: fade-out(#bebebe, 0.5);  
        color: #787878; 
    }
}