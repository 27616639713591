.node--view-mode-hiag-accordion-title {
  gap: $global-margin;
  margin: -#{$global-margin / 2} 0;

  .teaser-label {
    .teaser-label--date--day-num {
      color: change-color($accordion-item-color, $alpha: $field-label-alpha);

      & > .teaser-label--date--day-num-separator {
        border-color: change-color(
          $accordion-item-color,
          $alpha: $field-label-alpha
        );
      }
    }
  }
}

.accordion-title:hover,
.accordion-title:focus {
  .node--view-mode-hiag-accordion-title {
    .teaser-label {
      .teaser-label--date--day-num {
        color: change-color($anchor-color-hover, $alpha: $field-label-alpha);

        & > .teaser-label--date--day-num-separator {
          border-color: change-color(
            $anchor-color-hover,
            $alpha: $field-label-alpha
          );
        }
      }
    }
  }
}
