@import "settings";

.hiag-tags {
  display: flex;
  flex-wrap: wrap;
  gap: calc(#{$tag-gap} / 2) $tag-gap;

  @if ($tags-style == "buttons") {
    .hiag-tag {
      background-color: $tag-background-color;
      padding: $tag-padding;
      font-size: $tag-font-size;
      color: color-pick-contrast($tag-background-color, $black $white);
      transition: $tag-transition;

      @if ($tag-rounded) {
        --all-tag-padding: calc(#{$tag-padding} * 2);
        --tag-height: calc(#{$tag-font-size} + var(--all-tag-padding));
        border-radius: calc(var(--tag-height) * 0.5);
        padding-left: calc(#{$tag-padding} * 2);
        padding-right: calc(#{$tag-padding} * 2);
      } @else {
        border-radius: $tag-border-radius;
      }
    }

    a.hiag-tag:hover {
      background-color: $tag-background-color-hover;
      color: color-pick-contrast($tag-background-color-hover, $black $white);
    }
  } @else if ($tags-style == "labels") {
    .hiag-tag {
      font-size: $tag-font-size;
      position: relative;
      align-items: center;
      display: flex;

      &:not(:last-child):after {
        content: "";
        border-left: $tag-separator-border;
        position: absolute;
        color: $tag-background-color;
        right: 0;
        width: 0px;
        height: #{$tag-font-size * 1.5};
        max-height: $global-font-size;
        transform: translateX(calc(#{$tag-gap} / 2));
      }
    }
  }
}
