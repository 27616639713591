.hiag-chart-wrapper {
  display: flex;
  flex-wrap: wrap;

  canvas[data-max-cols="2"],
  canvas[data-max-cols="2"] ~ canvas {
    width: calc(100% / 2) !important;
    min-width: 400px;
    height: auto !important;

    @include breakpoint(medium down) {
      width: 100% !important;
    }
  }

  canvas[data-max-cols="3"],
  canvas[data-max-cols="3"] ~ canvas {
    width: calc(100% / 3) !important;
    min-width: 300px;
    height: auto !important;

    @include breakpoint(medium down) {
      width: 100% !important;
    }
  }

  canvas[data-max-cols="4"],
  canvas[data-max-cols="4"] ~ canvas {
    width: calc(100% / 4) !important;
    min-width: 200px;
    height: auto !important;

    @include breakpoint(medium down) {
      width: calc(100% / 2) !important;
    }
  }

  canvas[data-max-cols="5"],
  canvas[data-max-cols="5"] ~ canvas {
    width: calc(100% / 5) !important;
    min-width: 150px;
    height: auto !important;

    @include breakpoint(medium down) {
      width: calc(100% / 2) !important;
    }
  }
}
