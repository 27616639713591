@use "settings";

// Material Symbols from Google Fonts used LOCALLY (Downloaded 30.1.2023)
// Get references under: https://fonts.google.com/icons?selected=Material+Symbols+Outlined
//----------------------------------------------------------------------------------------
@font-face {
  font-family: "Material Symbols Outlined";
  font-style: normal;
  font-weight: 100 700;
  src: url(../src/fonts/material-symbols-outlined.woff2) format("woff2");
}

@font-face {
  font-family: "Material Symbols Rounded";
  font-style: normal;
  font-weight: 100;
  src: url(../src/fonts/material-symbols-rounded.woff2) format("woff2");
}

body {
  opacity: 0;
  animation-name: show;
  animation-duration: 200ms;
  animation-fill-mode: forwards;
  @keyframes show {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.material-symbols-outlined {
  font-family: "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;

  @include set-icon("expand_more");
}

.material-symbols-rounded {
  font-family: "Material Symbols Rounded";
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;

  @include set-icon("expand_more");
}

h4,
h6 {
  font-weight: bold;
}

h3,
h6 {
  opacity: 0.7;
}

li.icon-continue > a:after {
  @include mdi-icon("\e5e1");
  margin-left: #{0.5 * $global-margin};
}

html {
  @each $colorName, $colorValue in $foundation-palette {
    --#{$colorName}-color: #{$colorValue};
    @for $i from 1 through 5 {
      $percent: $i * 12;
      $current-alpha: $i * 0.16;
      --#{$colorName}-color-lighten-#{$i}: #{lighten($colorValue, $percent)};
      --#{$colorName}-color-transparent-#{$i}: #{change-color(
          $colorValue,
          $alpha: $current-alpha
        )};
    }
    @for $i from 1 through 3 {
      $percent: $i * 10;
      --#{$colorName}-color-darken-#{$i}: #{darken($colorValue, $percent)};
    }
  }

  --black: #{$black};
  --white: #{$white};
  --light-gray: #{$light-gray};
  --medium-gray: #{$medium-gray};
  --dark-gray: #{$dark-gray};

  --global-margin: #{$global-margin};
  --global-padding: #{$global-padding};
  --global-shadow-small: #{$global-shadow-small};
}

// External Links
//---------------

@each $name, $code in $anchor-icons {
  a {
    &.hiag-#{$name}-link {
      display: inline-block;
      gap: 4px;

      @if $name == phone {
        white-space: nowrap;
      }

      &:before {
        @include mdi-icon($code);
        padding-right: 4px;
        line-height: inherit;
      }
    }

    @if not $button-anchor-icon-show {
      &.button:before {
        content: none;
      }
    }
  }
}

// Button
//--------

.region-content .card .button {
  @if (#{$button-background} == #{$card-background}) {
    @include button-hollow;
    @include button-hollow-style(
      $button-background-alt,
      $button-hollow-hover-lightness,
      $button-hollow-border-width
    );
  }
}
ul.button-group,
ul.button-group.inline.links {
  display: flex;
  margin-left: 0;

  li.button {
    padding: $button-padding;
  }
}
@if not $button-anchor-icon-show {
  .button a {
    display: unset;
    color: $button-color;

    &:before {
      content: none;
    }
  }
}

.fc {
  .fc-button-primary {
    background-color: $button-background;
    border: $button-border;
    color: $button-color;

    &:disabled {
      background-color: change-color(
        $button-background,
        $alpha: $button-opacity-disabled
      );
      border: $button-border;
    }

    &:hover,
    &:not(:disabled).fc-button-active,
    &:not(:disabled):active {
      background-color: $button-background-hover;
      border: $button-border;
    }
  }
  .filter-menu li > a:hover {
    background-color: $menu-item-background-hover;
    color: color-pick-contrast($menu-item-background-hover, $black $white);
  }
  .calendar-filter-badge {
    width: 1em;
    height: 1em;
    border-radius: 50%;
    background: $primary-color;
    display: inline-block;
  }
}

.button > span.material-symbols-outlined {
  transform: scale(#{$button-icon-scale});
}

// Tables
//-------

table:not([class*="fc-"]) {
  @include hiag-table(
    $table-scroll,
    $table-stacked,
    $table-stack-breakpoint,
    $show-header-for-stacked-inline
  );
  display: table;

  tbody {
    border-bottom: none;
  }

  @if $table-has-open-sides {
    tr,
    th,
    tbody,
    thead,
    tfoot {
      border-left-color: transparent !important;
      border-right-color: transparent !important;
    }
  }

  .hiag-table-stacked-header {
    @include hiag-label($field-label-color, $field-label-alpha);
    a {
      @include hiag-label($anchor-color, $field-label-alpha);
    }
  }
}

table.stacked-by-width tr {
  display: flex;
  flex-direction: column;
  gap: $global-margin / 4;
  padding-top: $global-margin / 4;
  padding-bottom: $global-margin / 2;
  td {
    padding-top: 0;
    padding-bottom: 0;
  }
}

table.stacked-by-width thead {
  display: none;
}

table.stacked-by-width tbody tr .hiag-table-stacked-header {
  display: block;
}

// Scrollbars
//-----------

html,
body {
  scrollbar-width: thin;
  scrollbar-color: $scrollbar-thumb-color $scrollbar-track-color;

  &::-webkit-scrollbar {
    width: $scrollbar-width;
  }

  &::-webkit-scrollbar-track {
    background: $scrollbar-track-color;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $scrollbar-thumb-color;
    border-radius: $scrollbar-thumb-border-radius;
    border: $scrollbar-thumb-padding solid $scrollbar-track-color;

    &:hover {
      background-color: $scrollbar-thumb-hover-color;
    }
  }
}

// Admin Toolbar
#toolbar-administration {
  #toolbar-bar {
    background-color: $dark-gray;
    box-shadow: 0px 10px 10px #00000050;
    opacity: 0.7;
    transition: opacity 0.4s ease;
    z-index: 10000;

    * {
      box-shadow: none;
    }

    &:hover {
      opacity: 1;
    }
  }

  #toolbar-item-user-tray {
    box-shadow: 0px 10px 10px #00000050;
    border: none;
  }
}

// Drupal Off-Canvas
.dialog-off-canvas-main-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
}

/*
Page
---
*/

// Footer
footer {
  margin-top: $global-margin;
}

footer {
  padding-top: $global-padding;
  @include auto-regional-colors($footer-background-color);

  & > * {
    margin-left: $global-padding;
    margin-right: $global-padding;
  }

  table:not(.unstriped) {
    tbody {
      background-color: $footer-background-color;

      tr:nth-child(#{$table-stripe}) {
        background-color: change-color($black, $alpha: 0.2);
      }
    }
  }
  table.unstriped tbody tr {
    border-bottom: $table-border;
  }
  @if $footer-elevate {
    @include add-fake-shadow(
      top,
      $global-fake-shadow-height,
      $global-fake-shadow-color,
      $global-fake-shadow-transparency
    );
  }

  hr:first-child {
    margin-top: 0px;
  }
}

@if $accordion-content-border-style == open {
  .accordion {
    .accordion-title,
    .accordion-content,
    .accordion-item:first-child > :first-child,
    :last-child:not(.is-active) > .accordion-title,
    .accordion-item:last-child > :last-child {
      border-radius: 0;
      border-left-color: transparent;
      border-right-color: transparent;
    }
  }
}

*[hidden] {
  display: none !important;
}

.gin--horizontal-toolbar #toolbar-administration {
  z-index: 1000001;
}

.gin-secondary-toolbar.gin-secondary-toolbar--frontend {
  position: fixed;
  top: var(--gin-toolbar-height);
  width: 100vw;
  z-index: 1000000;
  opacity: 0;
  &.hidden:not(.active) {
    display: none;
  }

  &:hover {
    opacity: 1;
  }

  #toolbar-bar {
    .button {
      padding-left: 0;
      padding-right: 0;
      text-align: left;
      width: 100%;
      background-color: transparent;
      white-space: nowrap;
    }
  }
}

#gin-panel-margin-container {
  height: var(--gin-toolbar-height);
}

.responsive-blocks-content__item.card {
  overflow: visible !important;
}

a.edit-link,
a[href*="/edit?destination=/"] {
  width: fit-content !important;
  background: var(--gin-bg-layer3) !important;
  border: 1px solid var(--gin-border-color-layer2) !important;
  padding: 6px 10px !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15) !important;
  border-radius: 16px !important;
  box-sizing: border-box !important;
  margin: $global-margin;
  color: var(--gin-color-text-light) !important;

  &:hover {
    box-shadow: 0 0 0 1px var(--gin-color-focus-border),
      0 0 0 4px var(--gin-color-focus) !important;
    color: var(--gin-color-text) !important;
  }
}

.views-field-edit-node {
  a.edit-link,
  a[href*="/edit?destination=/"] {
    margin: 0 !important;
  }
}

iframe[src*="map.geo.admin"] {
  width: 100%;
  height: 30vh;
  min-height: 400px;
}

.card {
  .field__label {
    color: color-pick-contrast(
      $card-background,
      $field-label-color $field-label-color-dark
    );
  }
  &.node--type-person {
    .field__label {
      color: color-pick-contrast(
        $hiag-person-background-color,
        $field-label-color $field-label-color-dark
      );
    }
  }
}

// Calendar
@include breakpoint(medium down) {
  .fc-toolbar.fc-header-toolbar {
    flex-wrap: wrap;
    gap: $global-margin / 2;

    .fc-toolbar-chunk:not(:first-child):not(:last-child) {
      order: 1;
      width: 100%;
    }
  }
}

@media screen and (max-width: 470px) {
  .fc table {
    font-size: 0.9em;
  }
}

table[class*="fc-"] {
  margin-bottom: 0;

  table tr {
    display: table-row;
  }

  table :is(td, th) {
    display: table-cell;
  }

  tbody tr:nth-child(even) {
    background: transparent;
  }

  table {
    margin-bottom: 0;
  }
}

#hiag-main-menu.hiag-main-menu--is-safari.is-anchored {
  -webkit-transform: translateY(
    calc(0 * var(--title-bar-bottom-wrapper-height))
  );
}
