@import "settings";

.node--type-search-content {
  form .input-group {
    position: relative;
    &:last-child {
      margin-bottom: 0;
    }

    & > input {
      max-width: 420px;
      min-width: 200px;

      &:not(:focus) + .hiag-search-suggestions-container {
        transition: visibility 0s 0.25s;
        visibility: hidden;
      }
    }

    .hiag-search-suggestions-container {
      position: absolute;
      z-index: 1001;
      background: $white;
      width: 100%;
      box-shadow: $global-shadow-small;

      ul {
        list-style: none;

        li {
          padding: #{$global-padding / 2} $global-padding;
          border-bottom: $global-std-border;
        }
      }
    }
  }
}

.region-right-top-bar {
  .node--type-search-content {
    form .input-group {
      justify-content: flex-end;
    }
  }
}
